.footer_1 {
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
}

.final-words {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 5px;
}

.widget_title {
    text-align: center;
}

.social_widget {
    color: #fff;
    padding-top: 1px;
}

.menu_widgets {
    color: #fff;
}

.links-options-footer {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

img {

    height: 100% !important;
}

.footer-logo {
    color: #000000;
    cursor: pointer;
    text-decoration: none;
    font-size: 1rem;
    align-items: center;
    font-family: 'Bebas Neue', sans-serif;
    font-style: oblique;
    width: 15% !important;
    height: 100% !important;
}


.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.icon-social {
    color: #fff;
    font-size: 35px;
    text-align: center;
    flex-direction: row !important;
}

.icon-social:hover {
    color: #E60012;
    font-size: 38px;
}

.copyright {
    color: #fff;
}

.enlace {
    color: #fff;
}

.enlace:hover {
    color: #E60012;
}

.icon-menu {
    color: #fff;
    font-size: 20px;
    text-align: center;
    flex-direction: row !important;
    text-decoration: none !important;
}

.icon-menu:hover {
    color: #E60012;
}

.divisor-footer {
    border: 3px solid #E60012 !important;
    opacity: 10 !important;
    margin-top: -1px !important;
    margin-bottom: -1px !important;
}

.social-logo {
    cursor: pointer;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
}

.footer-horario {
    color: #fff;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .footer-logo {
        color: #000000;
        cursor: pointer;
        text-decoration: none;
        font-size: 1rem;
        align-items: center;
        font-family: 'Bebas Neue', sans-serif;
        font-style: oblique;
        width: 100% !important;
        height: 100% !important;
    }
}