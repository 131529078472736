.cards1 {
    padding-left: 10px;
    padding-right: 10px;
    color: #ffffff;
    background-color: #fff;
    background-position: center;
    font-family: 'Gill Sans', Calibri;
}

/* .video-mapa {
    padding-top: 80px;
    padding-bottom: 40px;
    max-height: 100%;
    max-width: auto;
    font-family: 'Gill Sans', Calibri;
} */

.comercios {
    /* font-size: 30px; */
    padding-top: 120px;
    color: #000;
    font-family: 'Gill Sans', Calibri;
}

.svg-container {
    /* background-color: #212529; */
    width: 90%;
    height: 90%;
}

.svg {
    width: 100%;
    height: 100%;
}

#mapa path {
    fill: #d71820;
    stroke: #ffffff;
    stroke-width: 2px;
}

#mapa :hover {
    fill: #c6c8ca;
}

.row {
    margin-left: -10px !important;
    margin-right: -10px !important;
}