@import url("https://fonts.googleapis.com/css?family=Raleway");

.body-marcas {
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
}

.flex-container {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: -webkit-flex;
    /* Safari */
    display: flex;
    overflow: hidden;
}

img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .flex-container {
        flex-direction: column;
    }
}

.flex-title {
    color: #f1f1f1;
    position: relative;
    font-size: 6vw;
    margin: auto;
    text-align: center;
    transform: rotate(90deg);
    top: 15%;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

@media screen and (max-width: 768px) {
    .flex-title {
        transform: rotate(0deg) !important;
    }
}

.flex-about {
    opacity: 0;
    color: #f1f1f1;
    position: relative;
    width: 70%;
    font-size: 2vw;
    padding: 5%;
    top: 20%;
    /* border: 2px solid #f1f1f1; */
    border-radius: 10px;
    line-height: 1.3;
    margin: auto;
    text-align: left;
    transform: rotate(0deg);
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

@media screen and (max-width: 768px) {
    .flex-about {
        padding: 0%;
        /* border: 0px solid #f1f1f1; */
    }
}

.flex-slide {
    -webkit-flex: 1;
    /* Safari 6.1+ */
    -ms-flex: 1;
    /* IE 10 */
    flex: 1;
    cursor: pointer;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

@media screen and (max-width: 768px) {
    .flex-slide {
        overflow: auto;
        overflow-x: hidden;
    }
}

@media screen and (max-width: 768px) {
    .flex-slide p {
        font-size: 2em;
    }
}

@media screen and (max-width: 768px) {
    .flex-slide ul li {
        font-size: 2em;
    }
}

.flex-slide:hover {
    -webkit-flex-grow: 3;
    flex-grow: 3;
}

.marcas {
    /* height: 100%;
    width: 100%; */
    object-fit: scale-down;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.hankook {
    height: 100vh;
    background-color: #E7E7E9;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

@media screen and (min-width: 960px) {
    .hankook {
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}

.dunlop {
    height: 100vh;
    background-color: #FFDE00;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

@media screen and (min-width: 960px) {
    .dunlop {
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}

.nankang {
    height: 100vh;
    background-color: #000;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

@media screen and (min-width: 960px) {
    .nankang {
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}

.chengshan {
    height: 100vh;
    background-color: #EE1C25;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

@media screen and (min-width: 960px) {
    .chengshan {
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}

.prinx {
    height: 100vh;
    background-color: #65DA00;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

@media screen and (min-width: 960px) {
    .prinx {
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}

.westlake {
    height: 100vh;
    background-color: #00492B;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

@media screen and (min-width: 960px) {
    .westlake {
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}

.corghi {
    height: 100%;
    width: auto;
    background-color: #E4031E;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

@media screen and (min-width: 960px) {
    .corghi {
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}

.weathertech {
    height: 100vh;
    background-color: #fff;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

@media screen and (min-width: 960px) {
    .weathertech {
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}



@keyframes aboutFlexSlide {
    0% {
        -webkit-flex-grow: 1;
        flex-grow: 1;
    }

    50% {
        -webkit-flex-grow: 3;
        flex-grow: 3;
    }

    100% {
        -webkit-flex-grow: 1;
        flex-grow: 1;
    }
}

@media screen and (min-width: 768px) {
    .flex-title-home {
        transform: rotate(90deg);
        top: 15%;
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}

@keyframes homeFlextitle {
    0% {
        transform: rotate(90deg);
        top: 15%;
    }

    50% {
        transform: rotate(0deg);
        top: 15%;
    }

    100% {
        transform: rotate(90deg);
        top: 15%;
    }
}

.flex-about-home {
    opacity: 0;
}

@media screen and (min-width: 768px) {
    .flex-about-home {
        animation: aboutFlexSlide 3s 1;
        animation-delay: 0s;
    }
}

@keyframes flexAboutHome {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.about {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/769286/beach-2089959_1280.jpg);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

.contact-form {
    width: 100%;
}

input {
    width: 100%;
}

textarea {
    width: 100%;
}

.contact {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/769286/lake-696098_1920.jpg);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

.work {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/769286/forest-208517_1280.jpg);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

.spinner {
    position: fixed;
    top: 0;
    left: 0;
    background: #222;
    height: 100%;
    width: 100%;
    z-index: 11;
    margin-top: 0;
    color: #fff;
    font-size: 1em;
}

.cube1,
.cube2 {
    background-color: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
    25% {
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    }

    50% {
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    }

    75% {
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    }

    100% {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes sk-cubemove {
    25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    }

    50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    }

    50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    }

    75% {
        transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    }

    100% {
        transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
    }
}