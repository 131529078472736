.cuerpo-contactos {
    width: 100%;
    background-color: #cccccc;
    margin: 0;
    font-family: helvetica;
}

.about {
    position: fixed;
    z-index: 10;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 0.2s ease;
}

.about .bg_links {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    backdrop-filter: blur(5px);
    position: absolute;
}

.about .logo {
    width: 40px;
    height: 40px;
    z-index: 9;
    background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 10px 7px;
    opacity: 0.9;
    transition: all 1s 0.2s ease;
    bottom: 0;
    right: 0;
}

.about .social {
    opacity: 0;
    right: 0;
    bottom: 0;
}

.about .social .icon {
    width: 100%;
    height: 100%;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    display: flex;
    transition: all 0.2s ease, background-color 0.4s ease;
    opacity: 0;
    border-radius: 100%;
}

.about:hover {
    width: 105px;
    height: 105px;
    transition: all 0.6s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}

.about:hover .logo {
    opacity: 1;
    transition: all 0.6s ease;
}

.about:hover .social {
    opacity: 1;
}

.about:hover .social .icon {
    opacity: 0.9;
}

.about:hover .social:hover {
    background-size: 28px;
}

.about:hover .social:hover .icon {
    background-size: 65%;
    opacity: 1;
}

.about:hover .social.portfolio {
    right: 0;
    bottom: calc(100% - 40px);
    transition: all 0.3s 0s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}

.about:hover .social.portfolio .icon:hover {
    background-color: #698fb7;
}

.about:hover .social.dribbble {
    bottom: 45%;
    right: 45%;
    transition: all 0.3s 0.15s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}

.about:hover .social.dribbble .icon:hover {
    background-color: #ea4c89;
}

.about:hover .social.linkedin {
    bottom: 0;
    right: calc(100% - 40px);
    transition: all 0.3s 0.25s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}

.about:hover .social.linkedin .icon:hover {
    background-color: #0077b5;
}

.wrapper {
    width: 100vw;
    margin: 0 auto;
    height: 400px;
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}

@media screen and (max-width: 767px) {
    .wrapper {
        height: 700px;
    }
}

.content-main {
    max-width: 1024px;
    width: 100%;
    padding: 0 4%;
    padding-top: 250px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .content-main {
        padding-top: 300px;
        flex-direction: column;
    }
}

.card {
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    height: 250px;
    background-color: #292929;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.3s ease;
}

.card-location {
    width: 100%;
    max-width: 150px;
    min-width: 200px;
    height: 250px;
    background-color: #292929;
    /* margin: 10px;
    border-radius: 10px; */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    /* border: 2px solid rgba(7, 7, 7, 0.12); */
    /* font-size: 16px;
    transition: all 0.3s ease; */
    position: relative;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    /* cursor: pointer; */
    /* transition: all 0.3s ease; */
}

.links {
    color: #fff;
}

.icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    background: linear-gradient(90deg, #F40A0F 0%, #fff 40%, rgba(0, 0, 0, 0.28) 60%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 30px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.card .text {
    width: auto;
    font-size: 35px;
    margin-top: 20px;
    color: white;
    transition: all 0.3s ease;
}

.card:hover {
    height: 270px;
}

.card:hover .info {
    height: 90%;
}

.card:hover .text {
    transition: all 0.3s ease;
    opacity: 1;
    /* max-height: 40px; */
}

.card:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
}

.card:hover .icon i {
    background: linear-gradient(90deg, #F40A0F, #FF4848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    transition: all 0.3s ease;
}